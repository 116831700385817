import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import pt from 'vuetify/es5/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        background: '#e1e5ee',
        primary: '#fcfcfc',
        secondary: '#3a567d',
        accent: '#82B1FF',
        error: '#f44336',
        warning: '#FFDE03',
        success: '#4caf50',
        info: '#2196f3',
      },
      dark: {
        background: '#121212',
        primary: '#202020',
        secondary: '#2D2D2D',
        accent: '#82B1FF',
        error: '#d32f2f',
        warning: '#FFDE03',
        success: '#388e3c',
        info: '#1976d2',
      }
    },
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
  icons: {
    iconfont: 'mdiSvg',
  }
});
