// import axios from 'axios'
import axios from "../config/axios";

export default {
  state: {
    sites: [],
    alerts: [],
    activeSite: null,
    updateTime: null,
  },
  getters: {
    sites: state => state.sites,
    alerts: state => state.alerts,
    activeSite: state => state.activeSite,
    updateTime: state => state.updateTime,
  },
  mutations: {
    setSites(state, sites) {
      state.sites = sites;
    },
    setActiveSite(state, site) {
      state.activeSite = site;
    },
    setAlerts(state, alerts) {
      state.alerts = alerts;
    },
    setUpdateTime(state, updateTime) {
      console.log(updateTime);
      state.updateTime = updateTime;
    },
  },
  actions: {
    async loadSites({ commit }) {
      const response = await axios.get("sites");
      commit("setSites", response.data.sites);
      commit("setAlerts", response.data.alarms);
    },
    async setActiveSite({ commit }, site) {
      commit("setActiveSite", site);
      // await dispatch("loadSiteAlerts", site._id);
    },
    async loadAndSetActiveSite({ dispatch, state }, _id) {
      const site = state.sites.find(s => s._id === _id);
      if (site) {
        dispatch("setActiveSite", site);
      } else {
        const response = await axios.get("sites");
        const sit = response.data.sites.find(s => s._id === _id);
        dispatch("setActiveSite", sit);
      }
    },
    clearActiveSite({ commit, dispatch }) {
      commit("setActiveSite", null);
      commit("setUpdateTime", null);
      dispatch("clearSiteAlerts");
    },
    setUpdateTime({ commit }, updateTime) {
      commit("setUpdateTime", updateTime);
    },
  },
};
