<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <router-view />
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
 components: {
 },
  name: 'App',
  computed:{
    ...mapGetters([
      'loggedUser',
      'config'
    ]),
    theme(){ return (this.$vuetify.theme.dark) ? 'dark' : 'light' }
  },
  watch: {
    loggedUser: {
      handler(loggedUser) {
        if (loggedUser) {
          // this.getConfig()
        }
      },
      immediate: true
    },
  },
  methods: {
    ...mapActions([
      // 'getConfig',
    ]),
  },
};
</script>
