import Vue from 'vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/pt-br'

dayjs.extend(relativeTime)


Vue.filter('formatDuration', (timestamp) => {

  let datetime = timestamp[0]
  let serverTime = timestamp[1]
  if (datetime < 1000000000000) {
    datetime *= 1000
  }

  if (serverTime < 1000000000000) {
    serverTime *= 1000
  }

  return dayjs(serverTime).locale('pt-br').from(datetime)
});

Vue.filter('formatDatetime', (timestamp) => {
  let datetime = timestamp
  if (timestamp < 1000000000000) {
    datetime *= 1000
  }

  return dayjs(datetime).format('DD/MM/YYYY HH:mm:ss')
});

Vue.filter('formatTime', (timestamp) => {
  let datetime = timestamp
  if (timestamp < 1000000000000) {
    datetime *= 1000
  }

  return dayjs(datetime).format('HH:mm:ss')
});

Vue.filter('formatTimeHm', (timestamp) => {
  let datetime = timestamp
  if (timestamp < 1000000000000) {
    datetime *= 1000
  }

  return dayjs(datetime).format('HH:mm')
});

Vue.filter('formatDate', (timestamp) => {
  let datetime = timestamp
  if (timestamp < 1000000000000) {
    datetime *= 1000
  }

  return dayjs(datetime).format('DD/MM/YYYY')
});
