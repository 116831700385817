// import axios from 'axios'
import axios from "../config/axios";

const infoUser = JSON.parse(sessionStorage.getItem("loggerUser"));
export default {
  state: {
    loggedUser: infoUser ? infoUser : null,
    user: infoUser ? infoUser : null,
    isAdmin: infoUser ? infoUser.isAdmin : null,
    config: null,
    themeDark: false,
    auth: null,
  },
  getters: {
    loggedUser: state => {
      return state.loggedUser;
    },
    getUser: state => {
      return state.user;
    },
    config: state => {
      return state.config;
    },
    theme: state => {
      return state.themeDark;
    },
  },
  mutations: {
    setchangeTheme(state, value) {
      state.themeDark = value;
    },
    setLoggedUser(state, credentials) {
      state.user = credentials;
    },
    setConfig(state, config) {
      state.config = config;
    },
  },
  actions: {
    async authenticate({ commit }, credentials) {
      let response = await axios.post(`login`, credentials);
      await sessionStorage.setItem("loggerUser", JSON.stringify(response.data));
      commit("setLoggedUser", response.data);
    },
    // async getConfig({ commit }) {
    //   const response = await axios.post('configs')
    //   commit('setConfig', response.data)
    // },
    logout({ commit }) {
      sessionStorage.removeItem("loggerUser");
      delete axios.defaults.headers.common["Authorization"];
      commit("setLoggedUser", null);
    },
  },
};
