import Vue from "vue";
import VueRouter from "vue-router";
import BaseView from "@/views/BaseView/BaseView";
import store from "../store";
import axios from "axios";

const Login = () =>
  import(/* webpackChunkName: "login" */ "../views/Login/Login");
const Overview = () =>
  import(/* webpackChunkName: "overview" */ "../views/Overview/Overview");
const SiteDashboard = () =>
  import(
    /* webpackChunkName: "sitedashboard" */ "../views/SiteDashboard/SiteDashboard"
  );
const AddSite = () =>
  import(/* webpackChunkName: "addsite" */ "../views/AddSite/AddSite");
const EditSite = () =>
  import(/* webpackChunkName: "editsite" */ "../views/EditSite/EditSite");

const TesteList = () =>
  import(/* webpackChunkName: "testelist" */ "../views/Test/index");

Vue.use(VueRouter);
axios.defaults.headers.common["Authorization"] = store.getters.loggedUser;
export const ROUTE_NAME = {
  LOGIN: "login",
  OVERVIEW: "overview",
  SITE_DASHBOAD: "site-dashboad",
  ADD_SITE: "add-site",
  EDIT_SITE: "edit-site",
};

const routes = [
  {
    name: ROUTE_NAME.LOGIN,
    path: "/login",
    component: Login,
  },
  {
    path: "/",
    component: BaseView,
    children: [
      {
        name: ROUTE_NAME.OVERVIEW,
        path: "/",
        component: Overview,
        beforeEnter: (to, from, next) => {
          const logado = JSON.parse(sessionStorage.getItem("loggerUser"));
          if (logado) {
            next();
          } else {
            next("/login");
          }
        },
      },
      {
        name: ROUTE_NAME.SITE_DASHBOAD,
        path: "/site/:siteId",
        component: SiteDashboard,
      },
      {
        name: ROUTE_NAME.ADD_SITE,
        path: "/add-site",
        component: AddSite,
      },
      {
        name: ROUTE_NAME.TEST_LIST,
        path: "/teste-list",
        component: TesteList,
      },
      {
        name: ROUTE_NAME.EDIT_SITE,
        path: "/edit-site",
        component: EditSite,
        beforeEnter: (to, from, next) => {
          if (store.getters.getUser.isAdmin == 1) {
            next();
          } else {
            store.dispatch("logout");
            next("/login");
          }
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  const logged = JSON.parse(sessionStorage.getItem("loggerUser"));
  if (to.name !== ROUTE_NAME.LOGIN && !logged) {
    next({ name: ROUTE_NAME.LOGIN });
  } else {
    next();
  }
});

export default router;
