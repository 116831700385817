<template>
  <div>
    <app-bar />
    <v-main>
      <v-slide-y-reverse-transition mode="out-in">
        <router-view />
      </v-slide-y-reverse-transition>
    </v-main>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar.vue'

export default {
  name: 'BaseView',
  components: {
    AppBar,
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-main__wrap {
  overflow: hidden;
}
</style>
