// import axios from 'axios'
import axios from '../config/axios'


export default {
  state: {
    devComms: [],
    lineChart: null,
    Intervalo: {},
  },
  getters: {
    devComms: ({ devComms }) => devComms,
    getLinecharts: ({ lineChart }) => lineChart,
    getIntervalo: ({ Intervalo }) => Intervalo,
  },
  mutations: {
    setDevComms(state, devComms) {
      state.devComms = devComms;
    },
    setLinechart(state, data) {
      state.lineChart = data;
    },
    Intervalo(state, startEnd) {
      state.Intervalo = startEnd;
    },
  },
  actions: {
    async loadDevComms({ commit }) {
      const response = await axios.post("devcomm");
      commit("setDevComms", response.data);
    },

    // async loadlinechart({ commit }, param) {
    //   console.log("linechartnew");
    //   const response = await axios.get("linechartnew", { params: param });
    //   commit("setLinechart", response.data);
    // },
  },
};
