import Vue from 'vue'
import Vuex from 'vuex'
import sites from './sites'
import devComm from './devComm'
import charts from "./charts";
import login from "./login";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    sites,
    // alerts,
    devComm,
    charts,
    login,
  },
});
