<template>
  <v-app-bar
    app
    class="app-bar"
    :color="this.$vuetify.theme.dark ? 'primary' : 'secondary'"
  >
    <v-slide-x-reverse-transition mode="out-in">
      <div v-if="!activeSite && !addSite">
        <v-img
          :src="require('../assets/LogoLigthHeader.png')"
          alt="Marca Conan"
          contain=""
          :height="logoHeight"
          class="cursor-pointer"
          @click="goHome"
        />
      </div>
      <v-btn v-else icon @click="goHome">
        <v-icon x-large color="#FFFFFF !important">
          {{ mdiChevronLeft }}
        </v-icon>
      </v-btn>
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <div v-if="activeSite" class="d-none d-flex align-center text-truncate">
        <div class="ml-4 title">
          {{ activeSite._id }}
        </div>
        <div class="ml-4 text--secondary text-truncate">
          {{ activeSite.title }}, {{ activeSite.city }}-{{ activeSite.state }}
        </div>
      </div>
      <div v-else-if="addSite" class="d-none d-md-block text-truncate">
        <div class="ml-4 title">
          Adicionar Site
        </div>
      </div>
    </v-slide-x-reverse-transition>
    <v-spacer />
    <!-- <v-btn
      icon
    >
      <v-icon
        color="#FFFFFF !important"
        @click="onAddSiteClick"
      >
        {{ mdiMapMarkerPlus }}
      </v-icon>
    </v-btn> -->

    <!-- <v-btn
      v-if="activeSite"
      icon
    >
      <v-icon
        color="#FFFFFF !important"
        @click="onDeleteSiteClick"
      >
        {{ mdiTrashCan }}
      </v-icon>
    </v-btn> -->
    <!-- <v-btn icon>
      <v-icon
        color="#FFFFFF !important"
        @click="switchDarkMode"
      >
        {{ mdiThemeLightDark }}
      </v-icon>
    </v-btn> -->
    <v-btn v-if="!activeSite & getUser.isAdmin == 1" class="ml-4" icon>
      <v-icon color="#FFFFFF !important" @click="clickEdit">
        {{ mdiSquareEditOutline }}
      </v-icon>
    </v-btn>
    <div class="loggedUser">
      <span class="title ml-4">{{ getUser.name }}</span>
      <v-btn icon>
        <v-icon color="#FFFFFF !important" @click="clickOnLogout">
          {{ mdiLogout }}
        </v-icon>
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import {
  mdiChevronLeft,
  mdiChartLine,
  mdiMapMarkerPlus,
  mdiTrashCan,
  mdiThemeLightDark,
  mdiTableArrowDown,
  mdiLogout,
  mdiSquareEditOutline,
} from "@mdi/js";
import { mapGetters, mapActions } from "vuex";
import { ROUTE_NAME } from "@/router";
// import ReportDialog from '@/components/ReportDialog'

export default {
  name: "AppBar",
  components: {
    // ReportDialog,
  },
  data() {
    return {
      mdiTableArrowDown,
      mdiChevronLeft,
      mdiChartLine,
      mdiMapMarkerPlus,
      mdiTrashCan,
      mdiThemeLightDark,
      mdiLogout,
      mdiSquareEditOutline,
      addSite: false,
    };
  },
  computed: {
    ...mapGetters(["loggedUser", "getUser", "activeSite", "updateTime"]),
    logoHeight() {
      return this.$vuetify.breakpoint.smAndDown ? "40" : "50";
    },
  },
  methods: {
    ...mapActions(["deleteSite", "getReport", "logout"]),
    clickOnLogout() {
      this.logout();
      this.$router.push({ name: ROUTE_NAME.LOGIN });
    },
    clickEdit() {
      this.$router.push({ name: ROUTE_NAME.EDIT_SITE });
    },
    goHome() {
      if (this.$route.name !== ROUTE_NAME.OVERVIEW) {
        this.$router.push({ name: ROUTE_NAME.OVERVIEW });
      }
    },
    switchDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      let val = this.$store.state.login.themeDark;
      this.$store.commit("setchangeTheme", (val = !val));
    },
    onAddSiteClick() {
      this.setAddSite();
      this.$router.push({ name: ROUTE_NAME.ADD_SITE });
    },
    onDeleteSiteClick() {
      // const res = this.deleteSite({that: this, site: this.activeSite});
      // if (res) this.$router.push({name: ROUTE_NAME.OVERVIEW});
      // else // TODO: Add toast informing about failed request
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";
@import "@/styles/mixins";

.app-bar .v-btn--disabled {
  opacity: 0.28;
}

.theme--light .app-bar .title,
.theme--light .app-bar .text--secondary,
.theme--light .v-card__title.text--secondary {
  color: #ffffff !important;
}
.loggedUser {
  border: 1px solid lightgray;
  border-radius: 7px;
  display: flex;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.v-btn.theme--light {
  background-color: map-get($material-dark, "secondary-light") !important;
  color: white !important;
}
.v-btn.theme--dark {
  background-color: map-get($material-dark, "primary") !important;
  color: inherit !important;
  .v-icon {
    color: white !important;
  }
}
</style>
