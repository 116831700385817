import axios from "../config/axios";
import Vue from "vue";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
export default {
  state: {
    lineChart: null,
    media: null,
    updateTime: null,
    corrent: {
      config: null,
      dataCurrent: [
        {
          data: [],
        },
      ],
    },
    tensao: {
      config: null,
      dataTensao: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],
    },
    temperatura: {
      config: null,
      dataTemp: [
        {
          data: [],
          name: "Temp 1",
        },
        {
          data: [],
          name: "Temp 2",
        },
        {
          data: [],
          name: "Temp 3",
        },
      ],
    },
    barChart: {
      tension: [
        {
          data: [],
        },
      ],
      temperatura: [
        {
          data: [],
        },
      ],
    },
    chartGaugeCorrent: null,
  },
  mutations: {
    setLinechart(state, data) {
      state.lineChart = data;
    },

    SET_MEDIA(state, data) {
      state.media = {
        meanc: data.meanC,
        meanT: data.meanT,
        meanV: data.meanV,
      };
    },

    SET_CORRENT_DATA(state, res) {
      let a = res.Xaxis;
      let b = [res.datavc];
      let c = res;

      state.corrent.config = {
        min: c.configs.configCmin,
        max: c.configs.configCmax,
      };
      let label = b[0].data.map((el, i) => {
        return [a.data[i] * 1000, el];
      });

      state.corrent.dataCurrent[0].data = label;

      if (state.corrent.dataCurrent.length > 0) {
        // this.$nextTick(() => {
        // });
        // this.$apexcharts.exec(
        //   "linechartCorrente",
        //   "updateSeries",
        //   [
        //     {
        //       data: state.corrent.dataCurrent[0].data,
        //     },
        //   ],
        //   true
        // );
      }
    },

    SET_TENSAO_DATA(state, res) {
      const tensao = {
        a: res.Xaxis,
        b: [res.datav1, res.datav2, res.datav3, res.datav4],
        c: res,
      };
      state.tensao.config = {
        min: tensao.c.configs.configVmin,
        max: tensao.c.configs.configVmax,
      };
      let test = null;
      tensao.b.forEach((item, index) => {
        if (item) {
          state.tensao.dataTensao[index].data = [];
          test = item.data.map((el, i) => {
            return [tensao.a.data[i] * 1000, el];
          });
          state.tensao.dataTensao[index].data = test;
          state.tensao.dataTensao[index].name = tensao.b[index].name;
        }
      });
    },

    SET_TEMPERATURA_DATA(state, res) {
      let a = res.Xaxis;
      let b = [res.datat1, res.datat2, res.datat3];
      let c = res;
      const stateTemp = state.temperatura.dataTemp;
      state.temperatura.config = {
        min: c.configs.configTmin,
        max: c.configs.configTmax,
      };

      let test = null;
      b.forEach((temp, index) => {
        if (temp) {
          stateTemp[index].data = [];

          test = temp.data.map((el, i) => {
            return [a.data[i] * 1000, el];
          });

          stateTemp[index].data = test;
          stateTemp[index].name = b[index].name;

          // VueApexCharts.updateSeries([
          //   {
          //     data: (stateTemp[index].data = test),
          //   },
          // ]);
        }
      });
    },

    SET_BAR_CHART(state, res) {
      // state.barChart = res;

      state.chartGaugeCorrent = res.cur / 10;
      state.barChart.tension[0].data = [
        res.v1 / 100,
        res.v2 / 100,
        res.v3 / 100,
        res.v4 / 100,
      ];

      state.barChart.temperatura[0].data = [res.t1, res.t2, res.t3];

      // this.$set(arr, 0, res.v1 / 100);
      // this.$set(arr, 1, res.v2 / 100);
      // this.$set(arr, 2, res.v3 / 100);
      // this.$set(arr, 3, res.v4 / 100);
      // this.barChartTensionArray = arr;
      // this.$set(this.TesnsaoSeries[0], "data", this.barChartTensionArray);

      // this.barChartTemperatureArray[0] = res.t1;
      // this.barChartTemperatureArray[1] = res.t2;
      // this.barChartTemperatureArray[2] = res.t3;

      // this.$set(
      //   this.TemperaturaSeries[0],
      //   "data",
      //   this.barChartTemperatureArray
      // );
    },

    SET_UPDATETIME(state, res) {
      state.updateTime = res.updateTime;
    },
  },
  actions: {
    async loadlinechart({ commit }, param) {
      const res = await axios.get("linechartnew", { params: param });
      commit("setLinechart", res.data);
      commit("SET_MEDIA", res.data);
      commit("SET_CORRENT_DATA", res.data);
      commit("SET_TENSAO_DATA", res.data);
      commit("SET_TEMPERATURA_DATA", res.data);
    },

    async loadbarchart({ commit }, param) {
      const res = await axios.get("devcommnew", { params: param });
      commit("SET_BAR_CHART", res.data);
      commit("SET_UPDATETIME", res.data);
    },
  },
  getters: {
    Linecharts: ({ lineChart }) => lineChart,
    media: ({ media }) => media,
    corrent: ({ corrent }) => corrent,
    tensao: ({ tensao }) => tensao,
    temperatura: ({ temperatura }) => temperatura,
    Barchart: ({ barChart }) => barChart,
    getgaugeChart: ({ chartGaugeCorrent }) => chartGaugeCorrent,
    getUpdateTime: ({ updateTime }) => updateTime,
  },
};
