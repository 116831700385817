import axios from "axios";
// console.log(axios.defaults)
// let token = JSON.parse(sessionStorage.getItem('loggerUser'))
// axios.defaults.headers.common['Authorization'] = token? token : null
// axios.defaults.baseURL = process.env.VUE_APP_API_URL
// axios.defaults.timeout = parseInt(process.env.VUE_APP_API_TIMEOUT, 10)

const Axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

Axios.interceptors.request.use(async config => {
  try {
    const token = await JSON.parse(sessionStorage.getItem("loggerUser"));
    if (token) {
      config.headers.Authorization = token.token;
    }
    return config;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
});

export default Axios;
